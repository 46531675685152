<template>
  <div class="spotDetails" v-if="spotDetails.title.length > 0">
    <meta-data :Title="spotDetails.metaTitle" :Description="spotDetails.metaDescription" :ImgUrl="spotDetails.images[0].attributes.formats.small.url"/>
    <el-main>
        <el-row>
        <el-col :xs="24" :sm="24" :md="14" :lg="16" :xl="16">
        <el-carousel id="carousel-id" v-if="spotDetails.images.length > 0" height="100" :interval="4000" class="caruselcontainer">
            <el-carousel-item v-for="item in spotDetails.images" :key="item.id" :v-once="handleResize">
                <img @load="handleResize" class="carousel-img" :src="item.attributes.url" :alt="item.attributes.alternativeText" rel="preload">
            </el-carousel-item>
        </el-carousel>
        </el-col>
        <el-col :xs="0" :sm="0" :md="10" :lg="8" :xl="8" style="padding-left: 20px;">
            <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-card class="box-card box-card-right book-screen-box first-box">
                <template #header>
                    <div class="card-header">
                    <span class="bookScreenHeader">Book skærm</span>
                    <span class="pr-week">pr. uge</span>
                    <span class="spot-price">{{ $filters.currencyDKK(priceObj.priceWithoutVAT !== 0 ? priceObj.priceWithoutVAT/selectedWeeksForBooking.length : selectedWeeksForBooking.length > 0 ? potDetails.pricePrWeek : spotDetails.priceLabel) }}</span>
                    </div>
                </template>
                <p>
                Vælg de uger I ønsker at booke
                </p>
                <!-- Content -->
                <div class="description" v-if="availableWeeks.length > 0">
                    <el-select-v2
                    v-model="selectedWeeksForBooking"
                    :options="availableWeeks"
                    @change="updateHeaderValues"
                    placeholder="Vælg uger"
                    style="width: 100%;"
                    multiple
                />

                <div v-if="spotDetails.priceLevels.length > 0">
                <p style="padding-top: 1rem;"><b>Antal visninger pr. uge (minimum)</b></p>
                <b><center class="priceRangeViews">{{ spotDetails.priceLevels[priceRangeSelectedIndex].views.toLocaleString('da-DK') }}</center></b>
                <input type="range" list="values" min="1" :max="spotDetails.priceLevels.length" value="0" @change="PriceRangeChange($event)" />
                <datalist id="values">
                    <option v-for="(priceLevel, index) in spotDetails.priceLevels" :key="index" :value="index" :label="priceLevel.label"> {{index}}</option>
                </datalist>
                <p style="color: #9433ff; padding-top: 1rem;"><b>Belægningsgrad</b></p>
                    Procenten ovenfor angiver den procentvise mængde af skærmen, som reklamepladsen vil udgøre. Jo højere procent, jo flere gange vil jeres
                    reklameplads blive vist på skærmen.
                </div>

                <div class="total-price-calculation" v-if="selectedWeeksForBooking.length > 0">
                    <table>
                        <tr>
                            <th class="total-price-tabel-description">Antal uger</th>
                            <th>{{ selectedWeeksForBooking.length }}</th>
                        </tr>
                        <tr v-if="priceObj.savings > 0">
                            <th class="total-price-tabel-description">Besparelse</th>
                            <th>{{ $filters.currencyDKK(priceObj.savings) }} kr</th>
                        </tr>
                        <tr>
                            <th class="total-price-tabel-description">Samlet pris:</th>
                            <th>{{ $filters.currencyDKK(priceObj.priceWithoutVAT) }} kr (ekskl. moms)</th>
                        </tr>
                    </table>
                </div>

                <el-button v-if="!successfullyAddedtoBasket && !isLoadingAvailableWeeks && selectedWeeksForBooking.length > 0" class="el-button-big booking-button" @click="addToBasket">Tilføj booking</el-button>
                <span v-if="successfullyAddedtoBasket" class="booking-added-to-cart">Booking tilføjet til kurven</span>
                </div>

                <span v-if="isLoadingAvailableWeeks">Vent venligst, mens vi henter ledige uger</span>
                <span v-if="availableWeeks.length === 0 && !isLoadingAvailableWeeks">Skærmen er desværre fuld booket. Prøv igen senere eller se en af vores andre skærme i samme område.</span>
            </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="spotDetails.spotSavings.length > 0">
            <el-card class="box-card box-card-right pricatical-info-box">
                <template #header>
                    <div class="card-header">
                    <span>Kampagne-pakker</span>
                    </div>
                </template>
                <!-- Content -->
                <div class="description spot-savings">
                    <span v-for="saving in spotDetails.spotSavings" :key="saving.id" class="discountDescription">Book {{ saving.attributes.Weeks }} uger og spar {{ saving.attributes.Percentage }}%</span>
                    
                </div>
            </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-card class="box-card box-card-right pricatical-info-box">
                    <template #header>
                        <div class="card-header">
                        <span>Praktiske oplysninger</span>
                        </div>
                    </template>
                    <!-- Content -->
                    <div class="description">
                        <div class="info-container">
                            <div class="info-name">Accepterede filtyper</div>
                            <div class="info-items">
                                <span v-for="filetype in spotDetails.spotFileTypes" :key="filetype.id" class="info-items tagbox" effect="plain">{{ filetype.attributes.Type }}</span>
                            </div>
                        </div>
                        <div class="info-container">
                            <div class="info-name">Opløsning (bredde x højde)</div>
                            <div class="info-items">
                                {{ spotDetails.resolutionWidth }} x {{ spotDetails.resolutionHeight }} pixels
                            </div>
                        </div>
                    </div>
                </el-card>
                </el-col>
            </el-row>
        </el-col>
        </el-row>
        <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-main class="el-main-content">
                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <div class="main-info">
                        <el-row>
                            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <div class="title-and-breadcrumb">
                                <div class="title"><h1>{{ spotDetails.title }}</h1></div>
                                <breadcrumb :Region="spotDetails.region" :City="spotDetails.city" :Address="spotDetails.address"> ss</breadcrumb>
                            </div>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <div class="statistics">
                                <i class="el-icon-view hidden-sm"></i>
                                <span>{{ ConvertToLocalString(spotDetails.viewsPrWeek * 4) }}</span>
                                <span style="margin-left: 5px">passerende pr. md</span>
                                <span class="infoIcon"><i @click="$router.push('/hjaelp?goTo=UdregningAfEksponering')" class="el-icon-question" /></span>
                            </div>
                            </el-col>
                            <el-col :xs="24" :sm="0" :md="6" :lg="6" :xl="6">
                            
                            </el-col>
                        </el-row>
                    </div>
                    </el-col>

            <el-col :xs="24" :sm="24" :md="0" :lg="0" :xl="0">
            <el-row style="margin-top: -5rem">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-card class="box-card box-card-right book-screen-box" style="width:100%;">
                    <template #header>
                        <div class="card-header">
                        <span class="bookScreenHeader">Book skærm</span>
                        <span class="pr-week">pr. uge</span>
                        <span class="spot-price" style="font-size: 22px;">{{ $filters.currencyDKK(priceObj.priceWithoutVAT !== 0 ? priceObj.priceWithoutVAT/selectedWeeksForBooking.length : selectedWeeksForBooking.length > 0 ? potDetails.pricePrWeek : spotDetails.priceLabel) }}</span>
                        </div>
                    </template>
                    <p>
                    Vælg de uger I ønsker at booke
                    </p>
                    <!-- Content -->
                    <div class="description" v-if="availableWeeks.length > 0">
                        <Selectdropdown :Data="availableWeeks" @selectchange="updateHeaderValues" />
                    

                    <div v-if="spotDetails.priceLevels.length > 0">
                        <p style="padding-top: 1rem;"><b>Antal visninger pr. uge (minimum)</b></p>
                        <b><center class="priceRangeViews">{{ spotDetails.priceLevels[priceRangeSelectedIndex].views.toLocaleString('da-DK') }}</center></b>
                        <input type="range" list="values" min="1" :max="spotDetails.priceLevels.length" value="0" @change="PriceRangeChange($event)" />
                        <datalist id="values">
                            <option v-for="(priceLevel, index) in spotDetails.priceLevels" :key="index" :value="index" :label="priceLevel.label"> {{index}}</option>
                        </datalist>
                        <p style="color: #9433ff; padding-top: 1rem;"><b>Belægningsgrad</b></p>
                            Procenten ovenfor angiver den procentvise mængde af skærmen, som reklamepladesn vil udgøre. Jo højere procent, jo flere gange vil jeres
                            reklameplads blive vist på skærmen.
                    </div>

                    <div class="total-price-calculation" v-if="selectedWeeksForBooking.length > 0">
                        <table>
                            <tr>
                                <th class="total-price-tabel-description">Antal uger</th>
                                <th>{{ selectedWeeksForBooking.length }}</th>
                            </tr>
                            <tr v-if="priceObj.savings > 0">
                                <th class="total-price-tabel-description">Besparelse</th>
                                <th>{{ $filters.currencyDKK(priceObj.savings) }} kr</th>
                            </tr>
                            <tr>
                                <th class="total-price-tabel-description">Samlet pris:</th>
                                <th>{{ $filters.currencyDKK(priceObj.priceWithoutVAT) }} kr (ekskl. moms)</th>
                            </tr>
                        </table>
                    </div>

                    <el-button v-if="!successfullyAddedtoBasket && !isLoadingAvailableWeeks && selectedWeeksForBooking.length > 0" class="el-button-big booking-button" @click="addToBasket">Tilføj booking</el-button>
                    <span v-if="successfullyAddedtoBasket" class="booking-added-to-cart">Booking tilføjet til kurven</span>
                    </div>

                    <span v-if="isLoadingAvailableWeeks">Vent venligst, mens vi henter ledige uger</span>
                    <span v-if="availableWeeks.length === 0 && !isLoadingAvailableWeeks">Skærmen er desværre fuld booket. Prøv igen senere eller se en af vores andre skærme i samme område.</span>
                </el-card>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="spotDetails.spotSavings.length > 0">
                <el-card class="box-card box-card-right pricatical-info-box" style="width:100%;">
                    <template #header>
                        <div class="card-header">
                        <span>Kampagne-pakker</span>
                        </div>
                    </template>
                    <!-- Content -->
                    <div class="description spot-savings">
                        <span v-for="saving in spotDetails.spotSavings" :key="saving.id" class="discountDescription">Book {{ saving.attributes.Weeks }} uger og spar {{ saving.attributes.Percentage }}%</span>
                        
                    </div>
                </el-card>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-card class="box-card box-card-right pricatical-info-box" style="width:100%;">
                    <template #header>
                        <div class="card-header">
                        <span>Praktiske oplysninger</span>
                        </div>
                    </template>
                    <!-- Content -->
                    <div class="description">
                        <div class="info-container">
                            <div class="info-name">Accepterede filtyper</div>
                            <div class="info-items">
                                <span v-for="filetype in spotDetails.spotFileTypes" :key="filetype.id" class="info-items tagbox" effect="plain">{{ filetype.attributes.Type }}</span>
                            </div>
                        </div>
                        <div class="info-container">
                            <div class="info-name">Opløsning (bredde x højde)</div>
                            <div class="info-items">
                                {{ spotDetails.resolutionWidth }} x {{ spotDetails.resolutionHeight }} pixels
                            </div>
                        </div>
                    </div>
                </el-card>
                </el-col>

                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-card class="box-card box-card-right pricatical-info-box" style="width:100%;">
                    <template #header>
                        <div class="card-header">
                        <span><b>Grafisk hjælp</b></span>
                        </div>
                    </template>
                    <!-- Content -->
                    <div class="description">
                        <div class="info-container">
                            <p>Skal vi lave dit budskab eller tilpasse jeres grafik?</p>
                            <p>Ved booking - hjælper vi jer uden ekstra omkostning.</p>
                        </div>
                    </div>
                </el-card>
                </el-col>
            </el-row>
            </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-card class="box-card" style="margin-top: 30px;">
                        <!-- Content -->
                        <div class="description" v-if="spotDetails.description">
                            <Markdown :source="spotDetails.description" />
                        </div>
                    </el-card>
                    </el-col>

                    <el-col class="hidden-sm" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-card class="box-card" style="margin-top: 30px;">
                        <template #header>
                            <div class="card-header">
                            <span>Placering</span>
                            <a v-if="spotDetails.StreetViewLink != null" :href="spotDetails.StreetViewLink" target="_blank"><span>Åben i Google Street View<i class="el-icon-link"></i></span></a>
                            </div>
                        </template>
                        <!-- Content -->
                        <div class="description" v-if="isLoaded" style="margin-top: 20px;">
                            <map-box :spots="spotDetailsList" :height="300" class="mapboxref" ref="mapbox"></map-box>
                        </div>
                    </el-card>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="spotDetails.FreeText">
                    <el-card class="box-card" style="margin-top: 30px;">
                        <template #header>
                            <div class="card-header">
                            <span>Beskrivelse af området</span>
                            </div>
                        </template>
                        <!-- Content -->
                        <div class="description">
                            <Markdown :source="spotDetails.FreeText" />
                            <div v-if="spotDetails.Citrusmedia">
                                <img src="@/assets/images/partners/citrus.png" width="250" height="75" alt="samarbejdspartner" />
                            </div>
                        </div>
                    </el-card>
                    </el-col>
                </el-row>
            </el-main>
        </el-col>

        <el-col :xs="0" :sm="10" :md="10" :lg="8" :xl="8">
        <el-main>
        
        </el-main>
        </el-col>
        </el-row>
    </el-main>
  </div>
</template>

<script>
import Breadcrumb from '../components/shared/Breadcrumb.vue';
import axios from 'axios';
import Calculation from '../servies/calculation.js';
import MapBox from '../components/shared/Map.vue';
import * as $ from 'jquery'
import qs from 'qs'
import Markdown from 'vue3-markdown-it'
import MetaData from '../components/shared/MetaData.vue'
import Selectdropdown from '../components/layouts/selectdropdown.vue';

export default {
  components: { Breadcrumb, MapBox, Markdown, MetaData, Selectdropdown },
  name: 'SpotDetails',
  data()  {
      return {
          isLoadingAvailableWeeks: true,
          spotDetailsList: [],
          spotDetails: {
              id: 0,
              priceLabel: 0,
              address: "",
              city: "",
              description: "test",
              pricePrWeek: 0,
              tags: "",
              title: "",
              viewsPrDay: 0,
              viewsPrWeek: 0,
              sizeHeight: 0,
              sizeWidth: 0,
              resolutionHeight: 0,
              resolutionWidth: 0,
              region: "",
              zipCode: "",
              spotFileTypes: [],
              spotSavings: [],
              images: [],
              Show: true,
              ShowPopUp: false,
              priceLevels: []
          },
          isLoaded: false,
          priceRange: {
              from: 0,
              to: 0
          },
          priceObj: {
            priceWithoutVAT: 0,
            VAT: 0,
            priceWithVAT: 0,
            savings: 0
        },
          availableWeeks: [],
          selectedWeeksForBooking: [],
          totalSavings: 0,
          totalPrice: 0,
          successfullyAddedtoBasket: false,
          windowWidth: window.innerWidth,
          priceRangeSelectedIndex: 0
      }
  },
  watch: {
      windowWidth(newWidth) {
        this.handleResize(newWidth);
      }
    },
  created() {
      this.getSpotDetails(this.$route.params.spotID);

      this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
      });
  },
  beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },
  updated() {
      this.$refs.mapbox.initializeMap();
  },
  methods: {
      PriceRangeChange(event) {
        this.priceRangeSelectedIndex =  event.target.value - 1;
        this.updateHeaderValues();
        
      },
      ConvertToLocalString(number) {
        return number.toLocaleString();
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      handleResize() {
        var height = $('.carousel-img').innerHeight();
        setTimeout(function () {$('.el-carousel__container').css('height', height +'px')}, 200);
      },
      convertSpotResponseToModel(response) {
        this.getAvialableDates(response.id)

        const attributes = response.attributes;
          this.spotDetails.id = response.id;
          this.spotDetails.metaTitle = attributes.metaTitle;
          this.spotDetails.metaDescription = attributes.metaDescription;
          this.spotDetails.address = attributes.Address;
          this.spotDetails.city = attributes.City;
          this.spotDetails.description = attributes.Description;
          this.spotDetails.pricePrWeek = attributes.PricePrWeek;
          this.spotDetails.tags = attributes.Tags;
          this.spotDetails.title = attributes.Title;
          this.spotDetails.viewsPrWeek = attributes.ViewsPrWeek;
          this.spotDetails.viewsPrDay = attributes.ViewsPrDay;
          this.spotDetails.sizeHeight = attributes.SizeHeight;
          this.spotDetails.sizeWidth = attributes.SizeWidth;
          this.spotDetails.resolutionHeight = attributes.ResolutionHeight;
          this.spotDetails.resolutionWidth = attributes.ResolutionWidth;
          this.spotDetails.region = attributes.region.data.attributes.Name;
          this.spotDetails.zipCode = attributes.ZipCode;
          this.spotDetails.spotFileTypes = attributes.spot_filetypes.data;
          this.spotDetails.spotSavings = attributes.spot_savings.data;
          this.spotDetails.images = attributes.Images.data;
          this.spotDetails.PositionLat = attributes.PositionLat;
          this.spotDetails.PositionLong = attributes.PositionLong;
          this.spotDetails.StreetViewLink = attributes.StreetViewLink;
          this.spotDetails.FreeText = attributes.FreeText;
          this.spotDetails.Citrusmedia = attributes.Citrusmedia ? attributes.Citrusmedia : false
          
          let exposure = attributes.Exposure;
          
          if(attributes.UsePriceLevel1 !== null && attributes.UsePriceLevel1) {
            this.spotDetails.priceLevels.push({ views: (exposure / 100) * 20, price: (this.spotDetails.pricePrWeek / 100) * 20, label: '2' })
          }
          if(attributes.UsePriceLevel2 !== null && attributes.UsePriceLevel2) {
            this.spotDetails.priceLevels.push({ views: (exposure / 100) * 40, price: (this.spotDetails.pricePrWeek / 100) * 40, label: '4' })
          }
          if(attributes.UsePriceLevel3 !== null && attributes.UsePriceLevel3) {
            this.spotDetails.priceLevels.push({ views: (exposure / 100) * 60, price: (this.spotDetails.pricePrWeek / 100) * 60, label: '6' })
          }
          if(attributes.UsePriceLevel4 !== null && attributes.UsePriceLevel4) {
            this.spotDetails.priceLevels.push({ views: (exposure / 100) * 80, price: (this.spotDetails.pricePrWeek / 100) * 80, label: '8' })
          } 
          if(attributes.UsePriceLevel4 !== null && attributes.UsePriceLevel5) {
            this.spotDetails.priceLevels.push({ views: exposure, price: this.spotDetails.pricePrWeek, label: '10' })
          }
          
          if(this.spotDetails.priceLevels.length > 0) {
            const level = this.spotDetails.priceLevels[0];
            this.spotDetails.priceLabel = level.price;
          }
          else {
            this.spotDetails.priceLabel = this.spotDetails.pricePrWeek;
          }

          this.calculatePriceRange();

        for(var i = 0; i < this.spotDetails.images.length; i++) {
            var meta = document.createElement('link');
            meta.setAttribute('rel', 'prefetch');
            meta.href = this.spotDetails.images[i].attributes.url;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
          

          this.spotDetails.spotSavings.sort(function(a, b) {
              var aWeek = a.attributes.Weeks;
              var bWeek = b.attributes.Weeks;
              return ((aWeek < bWeek) ? -1 : ((aWeek > bWeek) ? 1 : 0));
          });

          this.spotDetailsList.push(this.spotDetails);
          this.isLoaded = true;

      },
      getSpotDetails(spotID) {
          var query = qs.stringify({
              populate: '*'
          });
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}spots?${query}&filters[HumanizedLink][$eq]=${spotID}`, )
          .then(response => {
              this.convertSpotResponseToModel(response.data.data[0]);
          })
      },
      getAvialableDates(spotID) {
          axios.get(`${process.env.VUE_APP_BACKEND_URL}booking/GetAvailableDates?spotID=${spotID}`)
          .then(response => {
              var availableWeeksFromResponse = response.data.data;
              
              this.availableWeeks = [];
              if(availableWeeksFromResponse !== null) {
                for(var index = 0; index < availableWeeksFromResponse.length; index++)
                {
                    var weekNumber = availableWeeksFromResponse[index].weekNumber;
                    var monday = new Date(availableWeeksFromResponse[index].mondayDate);
                    var sunday = new Date(availableWeeksFromResponse[index].sundayDate);

                    var mondayString = monday.getDate() + '-' + (monday.getMonth() + 1) + '/' + monday.getFullYear();
                    var sundayString = sunday.getDate() + '-' + (sunday.getMonth() + 1) + '/' + sunday.getFullYear();

                    this.availableWeeks.push({
                        value: availableWeeksFromResponse[index].mondayDate,
                        label: `Uge ${weekNumber} | ${mondayString} - ${sundayString}`
                    })
                }
              }

              this.isLoadingAvailableWeeks = false;
          });
      },
      calculatePriceRange() {
          this.priceRange.to = this.spotDetails.pricePrWeek;
          this.priceRange.from = this.getMaxPriceForSingleWeek();
      },
      getMaxPriceForSingleWeek() {
          var pricePrWeek = this.spotDetails.pricePrWeek;
          var maxPercentage = 0;
          if(this.spotDetails.spotSavings.length > 0) {
              maxPercentage = this.spotDetails.spotSavings[this.spotDetails.spotSavings.length-1].Percentage;
          }
          return pricePrWeek - (pricePrWeek * (maxPercentage / 100));
      },
      updateHeaderValues(data) {
        if(data !== undefined) {
            this.selectedWeeksForBooking = data;
        }


        let pricePrWeek = this.spotDetails.pricePrWeek;
        if(this.spotDetails.priceLevels.length > 0) {
            pricePrWeek = this.spotDetails.priceLevels[this.priceRangeSelectedIndex].price;
        }
        this.priceObj = Calculation.calculateSpotPricesWithSavingsAndVAT(this.spotDetails.spotSavings, this.selectedWeeksForBooking.length, pricePrWeek, this.priceRangeSelectedIndex);
        this.spotDetails.priceLabel = this.selectedWeeksForBooking.length !== 0 ? this.priceObj.priceWithoutVAT : pricePrWeek;
        
        if(this.spotDetails.priceLevels.length > 0) {
        if(this.spotDetails.priceLabel === 0 && this.selectedWeeksForBooking.length === 0) {
            if(this.spotDetails.priceLevels.length > 0) {
                const level = this.spotDetails.priceLevels[this.priceRangeSelectedIndex];
                this.spotDetails.priceLabel = level.price;
            } 
        }
        else if (this.spotDetails.priceLabel === 0) {
            if(this.spotDetails.priceLevels.length > 0) {
                const level = this.spotDetails.priceLevels[0];
                this.spotDetails.priceLabel = level.price;
            }
        }
        }
      },
      addToBasket() {
          this.successfullyAddedtoBasket = true;

          var weeks = [];
          for(var i = 0; i < this.selectedWeeksForBooking.length; i++) {
            var date = new Date(this.selectedWeeksForBooking[i]);
            date.setHours(2, 0, 0, 0);
            weeks.push(date);
          }

          var basketObj = {
              basketID: Math.floor((Math.random() * 99999999) + 1),
              selectedWeeks: weeks,
              selectedWeeksCount: weeks.length,
              price: this.priceObj,
              title: this.spotDetails.title,
              city: this.spotDetails.city,
              address: this.spotDetails.address,
              image: this.spotDetails.images[0].attributes.formats.small.url,
              id: this.spotDetails.id,
              region: this.spotDetails.region,
              pricePrWeek: this.spotDetails.pricePrWeek,
              spotSavings: this.spotDetails.spotSavings,
              priceRangeSelectedIndex: this.priceRangeSelectedIndex
          };
          this.$store.commit('addItemToCart', basketObj);
      }
  }
}
</script>

<style scoped>
.caruselcontainer {
    border-radius: 6px; margin-top: 6rem;
}

@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .spotDetails {
        border-top: 1px solid rgb(204 204 204);
    }

    .el-main-content {
        padding-left: 0px important;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .caruselcontainer {
        margin-top: 0rem;
    }
    .book-screen-box {
        margin-top: 0px !important;
        width: 100% !important;
    }

    .pricatical-info-box {
        margin-top: 40px !important;
        width: 100% !important;
    }

    .mapboxref {
      height: 200px;
    }

    .description {
        font-size: 14px;
    }

    .spotDetails {
        margin-top: 50px !important;
    }

    .discountDescription {
        font-size: 18px !important;
    }

    .main-info {
        padding-bottom: 7rem;
    }

    .title h1 {
        line-height: 28px !important;
    }

    .statistics {
        margin-top: 0px !important;
        float: left !important;
    }

    .statistics span {
        font-size: 18px !important;
        padding-left: 0px !important;
    }

    .statistics span:nth-child(2) {
        margin-top: 15px !important;
    }
}

@media only screen and (max-width: 565px) {
    /* For mobile phones: */
    .carousel-img {
        max-height: 100% !important; 
        object-fit: cover;
    }
    .title h1 {
        font-size: 22px !important;
        height: 30px !important;
    }

    .statistics {
        margin-top: 0px !important;
        float: left !important;
    }

    .statistics span {
        font-size: 18px !important;
        padding-left: 0px !important;
    }

    .el-icon-view {
        font-size: 38px !important;
        margin-top: 12px;
    }

    .statistics span:nth-child(2) {
        margin-top: 15px !important;
    }
}

@media only screen and (max-width: 400px) {
    /* For mobile phones: */


    .spot-price {
        font-size: 16px !important;
        margin-top: 2px;
    }

    .bookScreenHeader {
        font-size: 16px !important;
    }

    .discountDescription {
        font-size: 14px !important;
    }
}

.spotDetails {
    width: 100%;
    max-width: 1488px;
    margin: 0 auto;
}

.pricatical-info-box {
    margin-top: 20px;
}

.pricatical-info-box .description {
    padding-top: 20px;
}

.book-screen-box {
    margin-top: 122px;
}

.title h1 {
    font-size: 32px;
    height: 10px;
    line-height: 10px;
}

.card-header {
    font-size: 18px;
    padding-bottom: 10px;
}

.card-header a {
    color: black;
    font-size: 14px;
    margin-left: 15px;
}

.saving-card {
    margin-left: 2%;
    float: left;
    width: 30%;
}

.box-card {
    padding-bottom: 20px;
}

.first-box {
    margin-top: 6rem !important;
}

.saving-card-week {
    float: left;
    width: 100%;
    text-align: center;
    height: 20px;
    font-size: 24px;
    margin-top: 20px;
}

.saving-card-amount {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    height: 100px;
    color: #9433ff;
}

.box-card-right {
    width: 95%;
    float: left;
    border-top: 5px solid #9433ff;
}

.carousel-img {
    max-height: 645px; 
    object-fit: cover;
}

.info-container {
    padding-bottom: 20px;
}

.info-item {
    font-size: 16px;
    margin-left: 5px;
}

.info-name {
    font-weight: 400;
    font-size: 18px;
}

.discountDescription {
    float: left;
    font-size: 22px;
    font-weight: 600;
    color: #31957B;
}

.spot-price {
    float: right;
    font-size: 26px;
    line-height: 26px;
    color: #31957B;
    font-weight: 600;
}

.pr-week {
    float: right;
    font-size: 18px;
    line-height: 26px;
    padding-left: 10px;
}

.title-and-breadcrumb {
    float: left;
}

.statistics {
    float: right;
    margin-top: 10px;
}

.statistics span {
    padding-left: 20px;
    font-size: 32px;
    float: left;
    font-weight: 600;
}

.statistics span:nth-child(3) {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
    padding-left: 10px;
}


.statistics i {
    font-size: 56px;
    font-weight: 400;
    float: left;
    color: #9433ff;
}

.main-info {
    float: left;
    width: 100%;
}

.total-price-title {
    margin-top: 5%;
}

.booking-button {
    width: 100%;
    max-width: 100%;
    margin-top: 6%;
    background-color: #31957B;
    color: white;
}

.booking-button:hover {
    background-color: white;
    color: #9433ff;
    border-color: #DCDFE6;
}

.booking-button:focus {
    background-color: transparent;
    border-color: #DCDFE6;
}

.booking-added-to-cart {
    color: #9433ff;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    float :left;
}

.total-price-calculation {
    float: left;
    margin-top: 20px;
}

.total-price-calculation tabel {
    border: 1px solid black;
}

.total-price-calculation th {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
}

.total-price-tabel-description {
    width: 100px;
}

.info-name {
    height: 30px;
}

.info-items {
    color: #9433ff !important;
    font-weight: 600;
}

.tagbox {
    font-size: 18px;
    padding-right: 10px;
}

.infoIcon i {
    font-size: 20px;
    margin-top: 22px;
    cursor: pointer;
    transition: 0.2s;
    margin-left: 20px;
}

.infoIcon i:hover {
    opacity: 0.7;
    transition: 0.2s;
}


datalist {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.3rem;
    padding: 0 0px;
}

datalist option {
    padding: 0;
    font-size: 18px;
    color: #31957B;
    font-weight: 600;
}

datalist option::after {
    content: "%"
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
}

input[type='range']::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #88e2ca, #31957B);
    height: 5px;
    
}
    
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -7px; /* Centers thumb on the track */
    background-color: rgb(250, 250, 250);
    border: 2px solid #31957B;
    border-radius: 100%;
    height: 1.2rem;
    width: 1.2rem;
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #31957B;
    height: 5px;
}
input[type="range"]::-moz-range-track {  
  background-color: #9a905d;
}

.priceRangeViews {
    color: #31957B;
    font-size: 22px;
}


</style>
