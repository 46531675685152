<template>
    <fieldset>
        <details>
            <summary>Vælg uger</summary>
            <ul>
                <li v-for="(item, index) in data" :key="index">
                    <label><input type="checkbox" @click="onClick" :name="item.label" :value="item.value" />{{ item.label }}</label>
                </li>
            </ul>
        </details>
    </fieldset>
</template>

<script>
export default {
  name: "SelectDropDown",
  props: {
    Data: [],
  },
  data() {
    return {
        data: this.Data,
        selectedData: [],
    }
  },
  mounted() {
  },
  methods: {
    onClick(clickEvent) {
        const res = clickEvent.target;
        if(res.checked) {
            this.selectedData.push(res.value);
        }
        else {
            const index = this.selectedData.indexOf(res.value);
            if(index > -1) {
                this.selectedData.splice(index, 1);
            }
        }

        this.callParent();
    },
    callParent() {
        console.log("emit")
        this.$emit('selectchange', this.selectedData);
    }
  }
 }

</script>

<style scoped>

@media only screen and (max-width: 768px) {
    
}

fieldset {
    
}
fieldset > label {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    vertical-align: top;
    font-size: 1rem;
    line-height: 28px;
}
fieldset > label::after {
    content: ":";
}
select,
details {
    display: inline-block;
    max-width: 250px;
    width: 100%;
    background-color: white;
    cursor: pointer;
}
select,
summary {
    border: 1px solid var(--lt-gray);
    border-collapse: collapse;
    border-radius: 4px;
    padding: 4px;
    width: 250px;
    background-color: white;
    cursor: pointer;
}
details[open] > summary::marker {
    color: var(--blue);
}
select:focus,
summary:focus,
summary:active {
    box-shadow: 0 0 5px 1px var(--blue);
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 2px;
    border: 1px solid var(--dk-gray);
    box-shadow: 0 0 5px 1px var(--lt-gray);
}
li {
    margin: 0px;
    padding: 0px;
}
li > label {
    cursor: pointer;
    display: inline-block;
    width: 100%;
}



</style>
