<template>
  <div class="breadcrumb">
      <div class="item">{{ Region }}</div>
      <div class="arrow">,</div>
      <div class="item">{{ City }}</div>
      <div class="arrow">,</div>
      <div class="item">{{ Address }}</div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
      Region: String,
      City: String,
      Address: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.breadcrumb {
    float: left;
    width: 100%;
    color: rgba(0, 0, 0, 0.7);
    padding-bottom: 2%;
}

.item {
    float: left;
    transition: 0.2;
}

.arrow {
    float: left;
    padding-left: 0px;
    padding-right: 5px;
}

</style>
